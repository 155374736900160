import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useLogin } from "../../hooks/useLogin.ts"; 

const ProtectedRoute: React.FC = () => {
  const { isLogged } = useLogin();
  return isLogged ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
