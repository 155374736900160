import { createStore, applyMiddleware } from "redux";
import rootReducer from "./rootReducers";
import { thunk } from "redux-thunk";

// const reduxDevTool =
//   process.env.NODE_ENV === 'development'
//     ? composeWithDevTools(
//         applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore, storage })),
//         reduxFirestore(fbConfig),
//       )
//     : compose(
//         applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore, storage })),
//         reduxFirestore(fbConfig),
//       );
const store = createStore(rootReducer, applyMiddleware(thunk));

// export const rrfProps = {
//   firebase,
//   config: (fbConfig, { useFirestoreForProfile: true, userProfile: 'users', attachAuthIsReady: true }),
//   dispatch: store.dispatch,
// };

// (process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

export default store;
