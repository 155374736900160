import { createBrowserRouter } from "react-router-dom";
import { Suspense, lazy, ReactNode } from "react";
import { Provider } from "react-redux";

// Importações internas
import store from "../redux/store.js";
import Page501 from "../container/pages/501/index.js";
import Dashboard from "../container/dashboard/index.tsx";
import PrivacyPolicy from "../container/pages/PrivacyPolicy/index.tsx";
import ProtectedRoute from "../components/protectedRoute/ProtectedRoute.tsx";
import React from "react";

// Lazy components
const Projects = lazy(() => import("../container/pages/Projects/index.tsx"));
const Page404 = lazy(() => import("../container/pages/404/index.js"));
const Client = lazy(() => import("../container/pages/Client/index.tsx"));
const Login = lazy(() => import("../container/pages/Login/index.tsx"));
const SignIn = lazy(() => import("../container/pages/SignIn/index.tsx"));
const SuccessSignIn = lazy(() => import("../container/pages/CadastroSucesso/index.tsx"));
const ClientDetails = lazy(
  () => import("../container/pages/ClientDetails/index.js")
);
const IntegrationPage = lazy(
  () => import("../container/pages/IntegrationPage/index.tsx")
);
const ProjectEdit = lazy(
  () => import("../container/pages/ProjectEdit/index.js")
);
const App = lazy(() => import("../App.tsx"));

// Função para encapsular o lazy loading e Suspense
const LazyLoad = (Component: React.FC): ReactNode => (
  <Suspense fallback={<div>Loading...</div>}>
    <Component />
  </Suspense>
);

// Router
const router = createBrowserRouter([
  {
    path: "/",
    element: <Provider store={store}>{LazyLoad(App)}</Provider>,
    children: [
      { path: "/", element: LazyLoad(Login) },
      { path: "/privacy-policy", element: LazyLoad(PrivacyPolicy) },
      { path: "/sign-in", element: LazyLoad(SignIn) },

      {
        path: "/",
        element: <ProtectedRoute />,
        children: [
          { path: "/projects", element: LazyLoad(Projects) },
          { path: "/client", element: LazyLoad(Client) },
          { path: "/client/:id", element: LazyLoad(ClientDetails) },
          { path: "/client/:id/integration", element: LazyLoad(IntegrationPage) },
          { path: "/projects/new", element: LazyLoad(ProjectEdit) },
          { path: "/projects/edit/:id", element: LazyLoad(ProjectEdit) },
          { path: "/501", element: LazyLoad(Page501) },
          { path: "/dashboard", element: LazyLoad(Dashboard) },
          { path: "/exit", element: LazyLoad(Login) },
          { path: "/success", element: LazyLoad(SuccessSignIn) },
        ],
      },
    ],
    errorElement: LazyLoad(Page404),
  },
]);

export default router;
