import { create } from "zustand";

interface LoginState {
  isLogged: boolean;
  setLogin: (logged: boolean) => void;
}

export const useLogin = create<LoginState>((set) => ({
  isLogged: false,
  setLogin: (logged) => set({ isLogged: logged }),
}));