const initialState = [
  {
    name: "woadud",
    email: "woaduda@gmail.com",
    mobile: "01742920502",
    id: 1,
  },
];

const themeUsersReducer = (state = initialState) => {
  return state;
};
export default themeUsersReducer;
