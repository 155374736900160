function Page501() {
  return (
    <div>
      <h1>Page 501</h1>
      <p>Pagina ainda não implementada!</p>
    </div>
  );
}

export default Page501;
