import React from "react";
import { Divider, Row, Col } from "antd";

const PrivacyPolicy: React.FC = () => {
    return (
        <section style={{ padding: "40px 0", backgroundColor: "#f4f4f9" }}>
            <Row justify="center">
                <Col span={24} style={{ textAlign: "center", paddingBottom: "20px" }}>
                    <h1 style={{ fontSize: "28px", color: "#000" }}>
                        Política de Privacidade e Termos de Uso - Metrika Fácil
                    </h1>
                    <Divider style={{ borderTop: "2px solid #000" }} />
                </Col>
            </Row>

            <Row justify="center">
                <Col xs={24} md={20} lg={16} style={{ backgroundColor: "#fff", padding: "40px", borderRadius: "8px", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}>
                    <section>
                        <h2 style={{ fontSize: "22px", color: "#000", marginBottom: "16px" }}>1. Controlador e Coletor de Dados</h2>
                        <p style={{ marginBottom: "24px", lineHeight: "1.6" }}>
                            O Métrica Fácil, disponível em metricafacil.com.br, é o responsável pelo tratamento dos dados pessoais, em conformidade com o Regulamento Geral de Proteção de Dados (GDPR), a Lei Federal de Proteção de Dados da Suíça (FADP), a Lei Geral de Proteção de Dados Pessoais (LGPD) e, para residentes nos EUA, com as regulamentações estaduais e federais aplicáveis à proteção de dados.
                        </p>

                        <h2 style={{ fontSize: "22px", color: "#000", marginBottom: "16px" }}>2. Dados Coletados</h2>
                        <ul style={{ marginBottom: "24px", lineHeight: "1.6" }}>
                            <li><strong style={{ color: "#000" }}>Dados Pessoais:</strong> Fornecidos diretamente pelo usuário ou coletados automaticamente durante o uso do aplicativo.</li>
                            <li><strong style={{ color: "#000" }}>Dados de Utilização:</strong> Coletados automaticamente para otimização, operação e manutenção dos serviços oferecidos.</li>
                        </ul>

                        <h2 style={{ fontSize: "22px", color: "#000", marginBottom: "16px" }}>3. Uso de Cookies e Tecnologias de Rastreamento</h2>
                        <p style={{ marginBottom: "24px", lineHeight: "1.6" }}>
                            Utilizamos cookies e outras ferramentas de rastreamento para melhorar a experiência do usuário e garantir o funcionamento adequado do aplicativo. Consulte nossa Política de Cookies para mais detalhes.
                        </p>

                        <h2 style={{ fontSize: "22px", color: "#000", marginBottom: "16px" }}>4. Processamento dos Dados</h2>
                        <p style={{ marginBottom: "24px", lineHeight: "1.6" }}>
                            Os dados são processados com medidas técnicas e organizacionais adequadas para proteger contra acessos não autorizados, alterações, divulgação ou destruição indevida.
                        </p>

                        <h2 style={{ fontSize: "22px", color: "#000", marginBottom: "16px" }}>5. Período de Retenção dos Dados</h2>
                        <p style={{ marginBottom: "24px", lineHeight: "1.6" }}>
                            Os dados pessoais serão mantidos apenas pelo tempo necessário para as finalidades previstas ou para cumprir obrigações legais.
                        </p>

                        <h2 style={{ fontSize: "22px", color: "#000", marginBottom: "16px" }}>6. Direitos dos Usuários</h2>
                        <ul style={{ marginBottom: "24px", lineHeight: "1.6" }}>
                            <li>Retirar o consentimento a qualquer momento.</li>
                            <li>Solicitar informações sobre o processamento de seus dados.</li>
                            <li>Corrigir, retificar ou atualizar os dados pessoais.</li>
                            <li>Excluir ou restringir o processamento dos dados pessoais.</li>
                            <li>Solicitar a portabilidade dos dados para outro controlador.</li>
                            <li>Registrar uma reclamação junto à autoridade competente de proteção de dados.</li>
                            <li>Objetar o processamento de seus dados com base em interesses legítimos ou para marketing direto.</li>
                        </ul>

                        <h2 style={{ fontSize: "22px", color: "#000", marginBottom: "16px" }}>7. Compartilhamento de Dados</h2>
                        <p style={{ marginBottom: "24px", lineHeight: "1.6" }}>
                            Os dados podem ser compartilhados com terceiros, como prestadores de serviços de TI, provedores de hospedagem e agências de comunicação, para cumprir as finalidades descritas nesta política.
                        </p>

                        <h2 style={{ fontSize: "22px", color: "#000", marginBottom: "16px" }}>8. Direitos Adicionais para Residentes nos EUA</h2>
                        <p style={{ marginBottom: "24px", lineHeight: "1.6" }}>
                            Se você for residente da Califórnia, Nevada ou de outros estados com leis de privacidade, você tem o direito de:
                        </p>
                        <ul style={{ marginBottom: "24px", lineHeight: "1.6" }}>
                            <li>Solicitar informações sobre quais dados pessoais são coletados e como são usados.</li>
                            <li>Optar por não permitir a venda de suas informações pessoais, se aplicável.</li>
                            <li>Solicitar a exclusão de seus dados pessoais.</li>
                        </ul>

                        <h2 style={{ fontSize: "22px", color: "#000", marginBottom: "16px" }}>9. Transferências Internacionais</h2>
                        <p style={{ marginBottom: "24px", lineHeight: "1.6" }}>
                            Os dados pessoais podem ser transferidos para fora da União Europeia, Suíça, Brasil ou Estados Unidos, com a devida proteção, conforme exigido por normas internacionais.
                        </p>

                        <h2 style={{ fontSize: "22px", color: "#000", marginBottom: "16px" }}>10. Alterações nesta Política</h2>
                        <p style={{ marginBottom: "24px", lineHeight: "1.6" }}>
                            O Métrica Fácil reserva-se o direito de modificar esta política a qualquer momento. Notificaremos os usuários sobre alterações através deste site e, quando apropriado, por outros meios.
                        </p>

                        <h2 style={{ fontSize: "22px", color: "#000", marginBottom: "16px" }}>11. Informações Adicionais</h2>
                        <p style={{ marginBottom: "24px", lineHeight: "1.6" }}>
                            Logs de sistema e dados técnicos, como endereços IP, podem ser coletados para fins de manutenção e operação do serviço.
                        </p>

                        <h2 style={{ fontSize: "22px", color: "#000", marginBottom: "16px" }}>12. Termos de Uso</h2>
                        <p style={{ marginBottom: "24px", lineHeight: "1.6" }}>
                            Ao utilizar o Métrica Fácil, o usuário concorda com o tratamento de seus dados pessoais conforme descrito nesta política. O não cumprimento das condições pode resultar na suspensão do acesso aos serviços.
                        </p>
                    </section>
                </Col>
            </Row>
        </section>
    );
};

export default PrivacyPolicy;
